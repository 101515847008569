import React, { useState, useEffect, useRef } from "react";
import { Link } from "@StarberryUtils"
import { graphql, navigate } from 'gatsby';
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import axios from 'axios';
import SEO from "../components/seo";
import logoBlack from "../images/redbrik-new-logo.svg";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import BannerPage from "../components/ProperyDetails/PropertyBannerInternational"
import DetailsPage from "../components/ProperyDetails/Details/InternationalDetails"
import LocRatingMap from '../components/map/loc-rating-map-area-guides';
import Description from "../components/ProperyDetails/PropertyDescription/PropertyDescription"
import Calculator from "../components/ProperyDetails/Calculator/Calculator"
import Properties from "../components/ProperyDetails/SimilarProperties/SimilarProperties"
import OtherInternationalDevelopments from "../components/ProperyDetails/OtherInternationalDevelopments"
import Contact from "../components/Home/ContactUs/Contact-Property-Details";

function getOffset( el ) {
    let _x = 0;
    let _y = 0;
    while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}

const PROPERTY_DETAILS_NEW = gql`
  query GetInternationalProperty ($slug: String!){
    internationalProperties(where:{slug: $slug, publish: true}) {
        id
        display_address
        street_name
        title
        status
        bedroom
        town
        district
        apartment {
            id
        }
        bungalow{
            id
        }
        house {
            id
        }
        townhouse{
            id
        }
        land {
            id
        }
        images
        floorplan
        media_images {
          url
        }
        media_floorplan {
            url
        }
        imagetransforms
        area        
        video
        search_type
        description
        latitude
        longitude
        additional_description
        search_description
        office_crm_id       
        price
        status
        office {
          New_Homes_Email
        }
        brochure {
            url
        }
        meta_title
        meta_description
        slug
    }
  }
`;

const InternationalDetailsTemplate = (props) => {
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    let myid_filt = pageurl.split('?');
    let mypageurl = myid_filt[0];
    let property_slug = mypageurl.split('-');
    let id = property_slug[property_slug.length - 1];
    id = id.replace("/", "");
    const locationurl = typeof window !== 'undefined' ? window.location.pathname : '';
    let propertyslug = locationurl?.split('/');
    const propurlSlug = propertyslug[2]?.split('-')?.slice(0, -1)?.join('-');


    const { loading, error, data: property_details } = useQuery(PROPERTY_DETAILS_NEW, {
        variables: { slug: propurlSlug }
    });

    let latitudes =  property_details?.internationalProperties[0]?.latitude
    let longitudes =  property_details?.internationalProperties[0]?.longitude

    const stickyRef = useRef();

    const handleNoProperty = () => {
         if (typeof window !== `undefined`) {
             window.location.href = '/international-property-for-sale/'
         }
    }

    useEffect(() => {
        window.addEventListener("scroll", windowScroll);
        return () => window.removeEventListener("scroll", windowScroll);
    }, []);

    const windowScroll = () => {
        if (stickyRef.current?.classList) {
            const top = getOffset(stickyRef.current).top - 150;
            if(window.pageYOffset > top){
                stickyRef.current.classList.add('is-sticked');
            } else {
                stickyRef.current.classList.remove('is-sticked');
            }
        }
    }

    

    if (loading) return (
        <section className={"loader-wrapper"}>
          <div id="loader-wrapper">
            <div id="loader" class="new-loader">
              <img className="logo-white loader-logo" src={logoBlack} alt="logo"/>
            </div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
          </div>
        </section>
    );
    if(property_details?.internationalProperties[0]?.slug != propurlSlug){
        navigate("/international-property-for-sale/")
    }
    return (
        <React.Fragment>
            {
                property_details && property_details.internationalProperties && (property_details?.internationalProperties[0]?.slug == propurlSlug) && property_details.internationalProperties.length > 0 ? property_details.internationalProperties.map((data, i) => {

                // Get Map Details
                let mapItems = [];
                let pid= data.id
                let mapItem = {};
                mapItem['lat']  = data.latitude
                mapItem['lng']  = data.longitude
                mapItem['id']  = data.id;
                const mapMarkerImageShowFlag = 0;
                mapItems.push( mapItem );
                let property_type = data.apartment ? "Apartment" : data.bungalow ? "Bungalow" : data.house ? "House" : data.townhouse ? "Townhouse" : "Land"
                // const metaTitle = data.title + ' in ' + data.display_address;
                const metaTitle = data.meta_title || `New ${property_type} ${data.search_type} with ${data.bedroom} bedroom in ${data.display_address} at £${data.Price}`;
                // const metaDesc = 'Book a viewing for this ' + data.title + ' in ' + data.display_address + ' at ' + data.Price + ' with Redbrik. Contact us for more information about this property.';
                const metaDesc = data.meta_description || `Find the details of New ${property_type} ${data.search_type} with ${data.bedroom} in ${data.display_address} at £${data.Price} with Redbrik. Request a viewing to get assistance in buying this new build ${property_type}`;
                return (
                    <div key={i}>
                        <div className="property-details-page new-homes-details">
                        <Header />
                        <SEO title={metaTitle} description={metaDesc} />
                        <div className='property-detail-content-main international-prop-detail' ref={stickyRef}>
                            <BannerPage propertyData={data} />
                            <DetailsPage propertyData={data} />
                            {(latitudes !== null && longitudes !== null) && (
                            <div className="property-details-landing-map my-5" id="property-details-map">
                                <LocRatingMap data={mapItems} />
                            </div>
                            )}
                            {/* <Description propertyData={data} /> */}
                            {(data.search_type !== "lettings" && data.search_type !== "To Rent") && (
                                <Calculator price={data.price} />
                            )}
                            {pid &&
                            <OtherInternationalDevelopments slug={pid}/>
                            }
                        </div>
                        {/* {
                            propItems.length > 0 &&
                            <Properties place={data.display_address} data={propItems} tag={data.department}/>
                        } */} 
                        <Footer popular_search="property_details" department="international" propertydetails={data}/>
                    </div>
                    </div>
                )
            }) : handleNoProperty()
            }
        </React.Fragment>
    )
}

export default InternationalDetailsTemplate
